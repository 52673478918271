import './polyfills';

import { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { handleLoginRedirect, main, store } from './application/configuration';
import { config } from './application/config';
import { ErrorBoundary } from './application/app/ErrorBoundary';
import './application/configuration/setup/datadog';
import { LoadingSpinner } from './features/common/displayComponents/LoadingSpinner';

const AppContainer = lazy(() => import(/* webpackChunkName: "SPA" */ './application/app/SPA.container'));

const renderApplication = (): void => {
    const root = document.getElementById('root');

    // Note that we need to use the base "Router" with a "hash" history
    // because the "HashRouter" doesn't allow handing in a history
    // from the outside. So this is effectively a "HashRouter" despite
    // that not being obvious here
    ReactDOM.render(
        <ErrorBoundary>
            <Provider store={store}>
                <BrowserRouter>
                    <Suspense fallback={<LoadingSpinner />}>
                        <Routes>
                            <Route path={'/'} element={<AppContainer />} />
                        </Routes>
                    </Suspense>
                </BrowserRouter>
            </Provider>
        </ErrorBoundary>,
        root
    );
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication);
}
