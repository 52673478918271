const getBoolEnvValue = (envValue: string): boolean =>
    typeof process.env[envValue] !== 'undefined' && process.env[envValue] === 'true';

interface ConfigState {
    backend: {
        AUTHENTICATION_SERVICE: string | undefined;
        MENU_SERVICE: string | undefined;
    };
    homeRoute: string | undefined;
    id: string | undefined;
    login: {
        authority: string | undefined;
        clientId: string | undefined;
        oauthScope: string[];
        mockAuthorization: boolean;
        mockUser?: string;
        mockLocale: string | undefined;
        preventRedirect: boolean;
        redirectUri: string | undefined;
        silentRedirectUri: string | undefined;
    };
    logoutUri: string | undefined;
}

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: process.env.REACT_APP_AUTHENTICATION_SERVICE,
        MENU_SERVICE: process.env.REACT_APP_MENU_SERVICE,
    },
    homeRoute: process.env.REACT_APP_HOME_ROUTE,
    id: process.env.REACT_APP_ID,
    login: {
        authority: process.env.REACT_APP_LOGIN_AUTHORITY,
        clientId: 'd0b7fda0-41eb-414f-8b42-68673df6911e',
        oauthScope: ['openid', 'profile', 'email'],
        mockAuthorization: getBoolEnvValue('REACT_APP_LOGIN_MOCK_AUTHORIZATION'),
        mockUser: process.env.REACT_APP_LOGIN_MOCK_USER,
        mockLocale: process.env.REACT_APP_LOGIN_MOCK_LOCALE,
        preventRedirect: getBoolEnvValue('REACT_APP_LOGIN_PREVENT_REDIRECT'),
        redirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URI,
        silentRedirectUri: process.env.REACT_APP_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: process.env.REACT_APP_LOGOUT_URI,
};
