import { get } from 'lodash';
import { LoginState, UserProfileType } from '../types';
import { State } from '../../redux/reducers';

const getBase = (state: State): LoginState => get(state, 'login');

export const isUserSessionExpired = (state: State): boolean => get(getBase(state), 'userSessionExpired');
export const getUserProfile = (state: State): UserProfileType | null => get(getBase(state), 'userProfile', null);
export const hasUserSessionEverExpired = (state: State): boolean =>
    get(getUserProfile(state), 'hasUserSessionEverExpired') ?? false;
export const getSubject = (state: State): string => get(getUserProfile(state), 'sub', '');
export const getUserAccount = (state: State): string => get(getUserProfile(state), 'account', '');
