import { combineReducers } from 'redux';
import { tokenHandlingReducer } from '../configuration/tokenHandling/reducer';
import { appReducer } from '../app/redux/App.reducers';
import { configReducer } from '../configuration/setup/configReducer';
import { langReducer } from '../configuration/lang/reducer';
import { loginReducer } from '../configuration/login/reducer';
import { productActivationsApi } from './api/productActivation';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const rootReducer = () =>
    combineReducers({
        config: configReducer,
        lang: langReducer,
        app: appReducer,
        login: loginReducer,
        tokenHandling: tokenHandlingReducer,
        productActivationsApi: productActivationsApi.reducer,
    });

const tmp = rootReducer();
export type State = ReturnType<typeof tmp>;
