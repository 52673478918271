// Necessary for evaluating types
import { every } from 'lodash';

type IsSubtype<S, P> = S extends P ? true : false;

type TypeEqual<T1, T2> = IsSubtype<T1, T2> extends true ? (IsSubtype<T2, T1> extends true ? true : false) : false;

export const assertTypeEqual = <T1, T2>(): TypeEqual<T1, T2> => undefined as any;

// Extraction of keys of a certain type from a generic type
type FunctionPropertyNames<T> = {
    [K in keyof T]: T[K] extends Function | undefined ? K : never;
}[keyof T];

export type FunctionProperties<T> = Pick<T, FunctionPropertyNames<T>>;

type NonFunctionPropertyNames<T> = {
    [K in keyof T]: T[K] extends Function | undefined ? never : K;
}[keyof T];

export type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>;

export const isOfType = <T>(object: Partial<T>): object is T => every(object, (value) => value !== undefined);
