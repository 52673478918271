import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { accessToken } from '../../configuration/tokenHandling/accessToken';
import { AssetBookings, AssetProduct, Products, ProductStatus } from '../../../domain/model/productInformation';
import { backendUrls } from './config';
import * as t from 'io-ts';
import { isOfType } from '../../../common/typescriptUtils/typeManipulation';
import { decode } from './iotsDecoder';

const productWithBookingDateAndStatus = t.type(
    {
        product: t.string,
        status_change_date: t.string,
        status: t.string,
    },
    'productWithBookingDateAndStatus'
);

const productActivationsApiAssetProductsCodec = t.type(
    {
        asset_products: t.record(t.string, t.array(productWithBookingDateAndStatus), 'assetProduct'),
    },
    'productActivationsApiResponse'
);

type ProductActivationsApiResponse = t.TypeOf<typeof productActivationsApiAssetProductsCodec>;
type ProductWithBookingDateAndStatus = t.TypeOf<typeof productWithBookingDateAndStatus>;
const decodeProductActivationsApiResponse = (parsedObject: unknown): ProductActivationsApiResponse =>
    decode(parsedObject, productActivationsApiAssetProductsCodec);

const mapToProductStatus = (data: string): ProductStatus | undefined => {
    switch (data) {
        case 'ACTIVE':
            return ProductStatus.ACTIVE;
        case 'PENDING_ACTIVATION':
            return ProductStatus.PENDING;
        default:
            console.warn(`Unknown product status found: ${data}`);
            return undefined;
    }
};

const mapToProducts = (data: string): Products | undefined => {
    switch (data) {
        case 'rio-sku00000065':
            return Products.FLEET;
        default:
            console.warn(`Unknown product found: ${data}`);
            return undefined;
    }
};

const mapToAssetProduct = (data: ProductWithBookingDateAndStatus): AssetProduct | undefined => {
    const assetProduct = {
        product: mapToProducts(data.product),
        status: mapToProductStatus(data.status),
        statusChangeDate: data.status_change_date,
    };
    return isOfType(assetProduct) ? assetProduct : undefined;
};

const mapToListOfAssetProduct = (data: ProductWithBookingDateAndStatus[]): AssetProduct[] =>
    data.map((it) => mapToAssetProduct(it)).filter((it): it is AssetProduct => typeof it !== 'undefined');

const mapToAssetBookings = (data: ProductActivationsApiResponse): AssetBookings => {
    const assetBookings: AssetBookings = {};
    Object.keys(data.asset_products).forEach(
        (assetId) => (assetBookings[assetId] = mapToListOfAssetProduct(data.asset_products[assetId]))
    );
    return assetBookings;
};

const transformProductActivations = (response: unknown): AssetBookings => {
    const decoded = decodeProductActivationsApiResponse(response);
    return mapToAssetBookings(decoded);
};

export const productActivationsApi = createApi({
    reducerPath: 'productActivationsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: backendUrls.PRODUCT_ACTIVATIONS_API,
        prepareHeaders: (headers) => {
            headers.set('authorization', `Bearer ${accessToken.getAccessToken()}`);
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getProductActivations: builder.query<AssetBookings, void>({
            query: () => '/product-activations',
            transformResponse: (response: unknown) => transformProductActivations(response),
        }),
    }),
});

export const { useGetProductActivationsQuery: useGetProductActivations } = productActivationsApi;
